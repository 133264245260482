.cursor-pointer {
  cursor: pointer !important;
}

/* start alertify.js confirm alert theme style */
.ajs-message {
  color: #1aa4dc !important;
  background-color: #d9edf7;
  font-weight: normal !important;
  font-family: "Montserrat" !important;
}

.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
  color: #fff !important;
  outline-style: none !important;
  background-color: #1aa4dc !important;
  font-weight: bold !important;
  font-family: "Montserrat" !important;
  border-radius: 5px !important;
}

.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-cancel {
  color: #fff !important;
  outline-style: none !important;
  background-color: #1aa4dc !important;
  font-weight: bold !important;
  font-family: "Montserrat" !important;
  border-radius: 5px !important;
}

.alertify .ajs-header {
  color: #000 !important;
  font-weight: bold !important;
  font-family: "Montserrat" !important;
}

.alertify .ajs-body {
  font-weight: normal !important;
  font-family: "Montserrat" !important;
}
/* end alertify.js confirm alert theme style */

/* Start: - react-trumbowyg  */
.trumbowyg-box {
  background-color: white !important;
  margin: 0px auto !important;
  min-height: 360px !important;
}
/* End: - react-trumbowyg  */

.tooltip-style {
  color: "#fff" !important;
  font-size: 12px !important;
  font-weight: bold !important;
}
