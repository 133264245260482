/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  background-color: #f2f2f2 !important;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat-Regular"),
    url("./fonts/Montserrat-Regular.ttf") format("woff");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  src: local("Montserrat-SemiBold"),
    url("./fonts/Montserrat-SemiBold.ttf") format("woff");
}
