.logout-btn {
  margin: 30px 15px 10px 15px;
  padding: 18px 12px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border: 0;
  border-radius: 4px;
  /* background-color: #1aa4dc; */
  /* outline: none; */
  background-color: transparent;
  border: 1px solid #000;
  cursor: pointer !important;
}

@media (min-width: 768px) {
  .show-mobile-drawer {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .hide-big-drawer {
    display: none !important;
  }
}
