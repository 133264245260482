.roles-title {
  font-size: 1.2rem;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 1.5;
}

.roles-div {
  /* text-align: center !important; */
  /* margin-left: 2%; */
}

.icons-spacing {
  padding-right: 15px;
}
